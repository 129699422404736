.progress-con {
  display: flex;
}

.progress-status {
  margin: 5px 0 10px;
  width: 300px;
  text-align: right;
  font-size: 20px;
  font-weight: 800;
}

.circle {
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border: 3px solid #000;
  border-radius: 50%;
}

.horizontal {
  position: relative;
  top: 11px;
  width: 250px;
  height: 3px;
  border: none;
  background-color: black;
}

.circle2 {
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border: 3px solid #000;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .progress-status {
    width: var(--large-screen);
  }

  .horizontal {
    width: calc(var(--large-screen) - 50px);
  }
}