/* Success and Error Pages Styles */

.process {
  font-size: 40px;
  font-weight: 900;
}

.icon {
  color: red;
  font-size: 100px;
}

.error {
  color: red;
}

.info {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.info-flex {
  display: flex;
  align-items: center;
}

.right-arrow-success {
  margin: 0 4px;
  width: 17px;
}

.warning {
  cursor: pointer;
  text-align: center;
  color: red;
  font-size: 20px;
  font-weight: 800;
}

.warning { /* Allow Copy Error message */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media (min-width: 768px) {
  .info {
    font-size: 20px;
  }

  .warning {
    font-size: 25px;
  }
}