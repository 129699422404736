/* Loading Icon CSS */
.loading {
    position: fixed;
    display: flex;
    width: 100%;
    /* height: 100%; */
    justify-content: center;
    align-self: center;
    z-index: 1000000000;
  }
  
  .loading img {
    width: 100px;
  }
  
  .loading-blur {
    height: 100vh;
  }