@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("../fonts/Pretendard-1.3.6/web/static/pretendard.css");

:root {
  --background-color: #fff;
  --text-color: #000;
  --primary: #ffc145;
  --primary-hover: #fcca45; /* +4096 (Decimal) */

  --default-screen: 300px; /* mobile */
  --large-screen: 350px; /* PC */
}

* {
  margin: 0;
  padding: 0;
  font-family: "Pretendard", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-color);
  color: var(--text-color);
  font-size: 10px;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.logo-container {
  height: 0;
  position: relative;
  bottom: 160px;
}

.umbrella-logo {
  width: 150px;
  padding-top: 10px;
}

.title {
  /* margin: 5px 0; */
  text-align: center;
  font-size: 3em;
  font-weight: 900;
}

/* Button */
.btn-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn {
  /* margin: 5px 0px; */
  width: 300px;
  height: 55px;
  font-size: 2em;
  font-weight: 800;
  background-color: var(--primary);
  border: none;
  box-shadow: 4px 4px 7px -3px rgb(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}

.btn {
  /* Align Button Image */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background-color: var(--primary-hover);
}

/* Scanner */

.scanner {
  width: 100%;
}

/* Bottom Text for Home.js */
.bottom-txt {
  display: flex;
  width: 300px;
  font-size: 13px;
  line-height: 15px;
}

.license,
.copy,
.ifu {
  font-weight: 100;
}

.copy {
  margin-left: 13px;
}

.ifu {
  cursor: pointer;
  margin-left: auto;
  background-color: transparent;
  border: none;
}

/* Footer for other pages */
.footer {
  position: fixed;
  bottom: 20px;
  margin-left: 45px;
}

.copyright {
  display: flex;
  font-size: 13px;
  line-height: 15px;
}

/* NEOFLUX Logo Gradient */
.neo {
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(-45deg, #4e9ff7, #f28369);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  .container {
    gap: 20px;
  }

  .btn-con {
    gap: 20px;
  }

  .logo-container {
    bottom: 180px;
  }

  .umbrella-logo {
    padding-top: 20px;
    width: 160px;
  }

  .btn-con {
    flex-direction: row;
  }

  .title {
    font-size: 4.5em;
  }

  .btn {
    width: var(--large-screen);
    height: 70px;
    font-size: 2.5em;
  }

  /* Scanner */
  .scanner {
    width: var(--large-screen);
  }

  .bottom-txt {
    font-size: 18px;
    width: calc(2 * (var(--large-screen) + 10px));
  }

  .ifu {
    font-size: 18px;
  }

  .copy {
    margin-left: 25px;
  }
}
