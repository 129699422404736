.overlay {
  opacity: 0;
  transition: opacity 0.1s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 99999999;
}

.overlay.show {
  opacity: 1;
}

.overlay-under {
  z-index: 9999 !important;
}

.popup-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100000000;
}

.popup-under {
  z-index: 10000 !important;
}

.popup-content {
  opacity: 0;
  transition: opacity 0.2s ease;
  width: calc(var(--default-screen) + 30px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 4px 4px 7px -3px rgb(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  gap: 10px;
}

.popup-content.show {
  opacity: 1;
}

.popup-close {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 15px;
  position: fixed;
  align-self: flex-end;
}

.popup-content > h1 {
  font-size: 20px;
  font-weight: 800;
  color: #000;
}

.popup-content > h2 {
  font-size: 20px;
  font-weight: 800;
  color: #ff0000;
}

.popup-content > h3 {
  font-size: 11px;
  font-weight: 600;
}

.popup-content > p {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.popup-small-buttons {
  display: flex;
  flex-direction: row;
}

.half { /* Small Popup Button */
  width: calc(var(--default-screen) / 2) !important;
}

/* Button Margin*/
.mr-5 {
  margin-right: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}


@media (min-width: 768px) {
  .popup-content {
    gap: 15px;
    width: calc(var(--large-screen) + 30px);
  }

  .popup-close {
    font-size: 20px;
  }

  .popup-content > h1 {
    font-size: 25px;
    font-weight: 800;
    color: #000;
  }
  
  .popup-content > h2 {
    font-size: 25px;
    font-weight: 800;
    color: #ff0000;
  }
  
  .popup-content > h3 {
    font-size: 13px;
  }
  
  .popup-content > p {
    font-size: 20px;
  }

  .half { /* Small Popup Button */
    width: calc(var(--large-screen) / 2) !important;
  }
}
