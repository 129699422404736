.std-form {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15vh;
  gap: 10px;
}

.std-form label {
  font-size: 20px;
  font-weight: 800;
  align-self: flex-start;
}

.std-form input {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border: 1px solid #000;
  border-radius: 27.5px;
  box-sizing: border-box;
}

.std-form input::placeholder {
  position: relative;
  left: 5px;
  font-size: 15px;
  font-weight: 200;
}

.std-form .input-warn {
  position: relative;
  line-height: 5px;
  color: red;
  font-size: 1.2em;
  font-weight: 700;
  align-self: flex-start;
}

/* Hide Spin Buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.right-arrow {
  width: 25px;
}

@media (min-width: 768px) {
  .std-form {
    gap: 15px;
    width: var(--large-screen);
  }

  .std-form input {
    height: 50px;
  }

  .right-arrow {
    width: 35px;
  }
}
